import React from 'react'
import Container from '../styles/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { graphql } from "gatsby"
import BlockContent from '@sanity/block-content-to-react'
import styled from 'styled-components'

export const query = graphql`
  query Impressum {
    impressum: sanitySiteSettings {
      _rawImpressumtext
    }
  }
`

const ContainerStyled = styled(Container)`
  h1, h2, h3 {
    font-family: "Quicksand";
    font-weight: bold;
  }
`;

const Impressum = props => {
  const {data, errors} = props
  return (
    <Layout>
      <SEO
        title="Impressum" />
      <ContainerStyled>
        <h2>Impressum</h2>
        <BlockContent blocks={data.impressum._rawImpressumtext} />
      </ContainerStyled>
    </Layout>
  )
}

export default Impressum